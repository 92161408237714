<template>
  <div class="page-coantainer">
    <el-dialog title="导入excel" :visible.sync="uploadData.isOpen" width="30%" :before-close="handleClose">
      <div class="dialog-body">
        <input type="file" name="" id="file" />
      </div>
      <el-button :disabled="isAbleUpload" style="margin-top: 20px;" type="primary" @click="uploadFile">上传</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { host } from '@/utils/service/url'
import axios from 'axios'
export default {
  props: {
    uploadData: {
      type: Object
    }
  },
  data () {
    return {
      uploadUrl: `${host}/company/importExecl`,
      isAbleUpload: false
    }
  },
  methods: {
    uploadFile () {
      const file = document.getElementById('file').files[0]
      const data = new FormData()
      data.append('file', file)
      if (file) {
        this.importCompanyExcel(data)
      } else {
        this.$message({
          message: '请选择文件',
          type: 'error'
        })
      }
    },
    importCompanyExcel (data) {
      this.isAbleUpload = true
      axios({
        url: this.uploadUrl,
        method: 'post',
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: window.sessionStorage.getItem('token')
        }
      })
        .then(() => {
          this.isAbleUpload = false
        })
        .catch(() => {
          this.isAbleUpload = false
        })
      setTimeout(() => {
        this.$message({
          type: 'success',
          message: '导入中,等待数据更新'
        })
        this.$emit('closeDialog', { type: 'success', isOpen: false })
      }, 6000)
    },
    /* 关闭弹窗 */
    handleClose () {
      this.$emit('closeDialog', { isOpen: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-coantainer {
  ::v-deep .el-dialog {
    &__body {
      max-height: 500px;
      overflow: auto;

      form {
        .submit {
          padding: 10px 30px;
          border: none;
          color: #fff;
          background-color: #409eff;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
